<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix dashboard-card-title">
				<svg-icon icon-class="commerce" class="dashboard-icon-card" />
				<span class="head-title">Extensie Google Chrome</span>
			</div>
			<div class="card-body">
				<div class="mini-title">
					Importa produsele din marketplace-ul competitorului in trei pasi simpli:
				</div>
				<div class="title"></div>
				<div><strong>Pasul 1.</strong> Genereaza o cerere de export.</div>
				<div><strong>Pasul 2.</strong> Asteapta emailul care iti confirma cate produse au fost acceptate.
				</div>
				<div><strong>Pasul 3.</strong> Imediat dupa aprobare, activeaza produsele care vrei sa apara pe site
					si seteaza-le stocul real.</div>
				<p>
					<router-link :to="{name: 'Extensie Browser'}">
						<el-button plain class="fullWidth b-r-eb" type="primary">{{$t('general.details')}}</el-button>
					</router-link>
				</p>

			</div>
		</el-card>
	</div>
</template>

<script>
	import smartBillImage from '@/assets/images/smartbill_logo_small.png';
	export default {
		components: {

		},
		data() {
			return {
				smartBillImage
			};
		},
		computed: {

		},
		methods: {

		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.dashboard-icon-card {
		font-size: 27px !important;
		vertical-align: middle;
		color: #ff601d;
	}

	.head-title {
		vertical-align: middle;
		font-weight: 500;
		margin-left: 3px;
	}

	.mini-title {
		font-size: 14px;
		font-weight: 500;
		padding-bottom: 10px;
	}

	.card-body {
		font-size: 13px;
	}

	a {
		color: #ff601d;
	}
</style>